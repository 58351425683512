:root {
  --bs-body-font-family: 'Roboto', sans-serif !important;
}

.container-fluid {
  max-width: 1140px;
}

.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
}

/* scroll animation */
@-moz-keyframes scroll {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes scroll {
  0% {
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%);
  }
}
